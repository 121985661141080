const rangeInput = document.querySelector("#vendor_reg_min_price");
const rangeLabel = document.querySelector('label[for="vendor_reg_min_price"]');
let rangeVal = rangeInput.value;

const currentValSpan = document.createElement('span');
let currentValSpanText = document.createTextNode(' ' + rangeVal);
currentValSpan.appendChild(currentValSpanText);
rangeLabel.appendChild(currentValSpan);

rangeInput.addEventListener('change', function () {
    let rangeVal = rangeInput.value;
    currentValSpan.textContent = ' ' + rangeVal;
});


//Reg form functionality
const form = document.querySelector('#vendor_registration_form');

const successDiv = document.querySelector('#vendor-registration-confirmation-message');
const messageDiv = form.querySelector('.form-message');

form.addEventListener('submit', evt => {
    evt.preventDefault();

    const submitButton = form.querySelector('button[type="submit"]');

    messageDiv.classList.add('d-none');
    messageDiv.classList.remove('alert-error', 'alert-success');

    submitButton.innerHTML = 'Processing...';
    submitButton.disabled = true;

    //Validate the form
    $(form).validate();

    if ($(form).valid()) {
        let formData = new FormData(form);

        axios.post('/portal/vendor/register', formData)
            .then(res => {
                successDiv.classList.remove('d-none');
                form.classList.add('d-none');

                submitButton.innerHTML = 'Submit';
                submitButton.disabled = false;

            })
            .catch(error => {
                submitButton.innerHTML = 'Submit';
                submitButton.disabled = false;

                form.scrollIntoView({
                    block: "center",
                    behavior: "smooth"
                });

                console.error(error);

                if (error.response) {
                    messageDiv.classList.add("alert-danger");
                    messageDiv.classList.remove('d-none');

                    //Need to create error list
                    let errorUl = document.createElement('ul');

                    for (const key in error.response.data.errors) {
                        if (error.response.data.errors.hasOwnProperty(key)) {
                            const msg = error.response.data.errors[key][0];

                            errorUl.innerHTML += `<li>${msg}</li>`

                        }
                    }

                    let errorMessageParagraph = document.createElement('p');

                    errorMessageParagraph.innerHTML = error.response.data.message;

                    messageDiv.innerHTML = errorMessageParagraph.innerHTML + errorUl.innerHTML;
                } else {
                    logApplicationError(error);

                    messageDiv.classList.add("alert-success");
                    messageDiv.classList.remove('d-none');
                    messageDiv.innerHTML = "<p>Your form has been successfully submitted. Thanks!</p>";
                }

            });
    } else {
        submitButton.innerHTML = 'Submit';
        submitButton.disabled = false;
    }
});

$('#vendor-registration-form-modal').on('hidden.bs.modal', function(evt) {
    form.classList.remove('d-none');
    successDiv.classList.add('d-none');
    messageDiv.classList.add('d-none');

    form.reset();
})
